/*eslint-disable jsx-a11y/anchor-is-valid */
import "../../css/main.css";
import "../../css/demo_table.css";
import cloud_security_solutions_logo from "../../images/cloud_security_solutions_logo.png";
import Thales_logo_white from "../../images/Thales_logo_white.png";
import { useNavigate } from "react-router";
import { TableOutline } from "./RequestListTable/TableOutline";
import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router";
import Cookies from "js-cookie";

export const RequestListsPage = () => {
  let history = useNavigate();
  const { state } = useLocation();

  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = null,
    productData  = [];

  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = localStorage.getItem("CPS_UserEmail");
    }
    if (localStorage.getItem("CPS_PreviousValue") !== null) {
      previousValue = localStorage.getItem("CPS_PreviousValue");
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = localStorage.getItem("CPS_NextValue");
    }
  } else {
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    productData  = state.productData ;
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem("CPS_PreviousValue", JSON.stringify( previousValue!== null ? previousValue.replaceAll('"', "") : null));
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
  }

  function handleSubmit(event) {
    axios
      .post(
        process.env.REACT_APP_END_POINT + "/LogoutPage",
        {
          headers: {
            "x-csrf-token": Cookies.get("csrf_access_token"),
          },
          mode: "cors",
        }
      )
      .then((response) => {
        localStorage.clear();
        history("/");
      })
      .catch(function (error) {
        Cookies.remove("csrf_access_token");
        localStorage.clear();
        history("/");
      });
  }

  useEffect(() => {
    if (Cookies.get("csrf_access_token") === undefined) {
      localStorage.clear();
      history("/");
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <html>
      <head>
        <title>Cloud Provisioning System :: User '{email}'</title>
        <link rel="stylesheet" 
        type="/text/css" 
        href="/css/demo_table.css" />

        <link
          rel="stylesheet"
          type="/text/css"
          href="/css/ui-lightness/jquery-ui-1.8.2.custom.css"
        />
        <link rel="stylesheet" href="/css/main.css" 
        type="/text/css" 
        />
      </head>
      <body>
        <div className="header_title">
          <img
            align="left"
            style={{position:"relative",left:"15px"}}
            src={cloud_security_solutions_logo}
            id="logo"
            width="300"
            height="45"
            alt="Cloud Security Solutions Logo"
          />
          <div id="signininfo">
            Welcome,{" "}
            {localStorage.getItem("CPS_UserName") !== null
              ? localStorage.getItem("CPS_UserName").replaceAll('"', "")
              : " "}
            &nbsp;|&nbsp;
            <a onClick={handleSubmit}>
              <u>Log out</u>
            </a>
          </div>
          <div id="topmenu">
            <ul>
              <li>
                <a
                  title="New request wizard"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/provisioningrequest", {
                      state: {
                        Token: Token,
                        email: email,
                        password: password,
                        previousValue: null,
                        nextValue: null,
                        productData : productData ,
                      },
                    });
                  }}
                >
                  New Provisioning Request
                </a>
              </li>
              <li>
                <div className="selected" title="Requests list">
                  Requests List
                </div>
              </li>
            </ul>
          </div>

          <img
            src={Thales_logo_white}
            position="relative"
            id="logo_safenet"
            width="180"
            height="44"
            alt="Gemalto Logo White"
          />
        </div>
        <div id="pageContent">
          <div id="header"></div>

          <div className="pagecontent">
            <div className="page-title">Cloud Provisioning System</div>
            <div className="page-description">
              You can view all your previous provisioning requests below. Please
              use the "New Requests Wizard" to provision a new AMI.
            </div>
            <TableOutline></TableOutline>
            <script
              type="text/javascript"
              src="/js/jquery-1.4.2.min.js"
            ></script>
            <script
              type="text/javascript"
              language="javascript"
              src="/js/jquery.dataTables.js"
            ></script>
          </div>
        </div>
        <div id="footer">copyrights and stuff</div>
      </body>
    </html>
  );
};

export default RequestListsPage;
