import cloud_security_solutions_logo from "../../images/cloud_security_solutions_logo.png";
import Thales_logo_white from "../../images/Thales_logo_white.png";

export const HeaderImages = () => {
  return (
    <div 
    className="header_title">
      <img
        align="left"
        style={{position:"relative",left:"15px"}}
        src={cloud_security_solutions_logo}
        id="logo"
        width="300"
        height="auto"
        alt="Cloud Security Solutions Logo"
      ></img>
      <img
        src={Thales_logo_white}
        style={{paddingTop:"15px"}}
        position="relative"
        id="logo_safenet"
        width="180"
        height="44"
        alt="Gemalto Logo White"
      ></img>
    </div>
  );
};

export default HeaderImages;
