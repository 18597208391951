export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <label>
        Search:
        <input
          value={filter || ""}
          type="text"
          id="myInput"
          onChange={(e) => setFilter(e.target.value)}
        ></input>
      </label>
    </span>
  );
};
