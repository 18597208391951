import HeaderElements from "../LoginPage/PageHeader";
import RequestPageImages from "./RequestPageImages";
import "../../css/main.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useRef, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";

const SelectProductVersion = () => {
  const { state } = useLocation();

  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = useRef(null),
    productData = [];

  const history = useNavigate();

  const [productStatus, setProductStatus] = useState("");
  const [disable, setDisable] = useState(false);
  const [classNameButton, setClassNameButton] = useState(
    "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
  );
  const [prodData , setProdData] = useState([])

  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = JSON.parse(localStorage.getItem("CPS_UserEmail"));
    }
    if (localStorage.getItem("CPS_PreviousValue") !== null) {
      previousValue = JSON.parse(localStorage.getItem("CPS_PreviousValue"));
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = JSON.parse(localStorage.getItem("CPS_NextValue"));
    }
  } else {
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    if(state.productData!=null && state.productData!=undefined && state.productData.length > 0)
    {
      productData= state.productData;
    }
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem(
      "CPS_PreviousValue",
      JSON.stringify(
        previousValue 
        !== null ? previousValue.replaceAll('"', "") : null
      )
    );
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
  }

  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionName, setSelectedOptionName] = useState();

  let [result, setResult] = useState([]);

  const common = (productData) => {
    
    setProductStatus("");
    setDisable(false);
    let ProductVersions = productData;
    setProdData(ProductVersions)
    //response.data;
    let result2 = [];
    var previousValue2 =
      previousValue !== null ? previousValue.replaceAll('"', "") : null;
    for (var key in ProductVersions) {
      if (key === previousValue2) {
        Object.assign(result2, ProductVersions[key]);
        break;
      }
    }

    result2 = result2.sort(function(a, b) {
      var nums1 = a.Product_version.split(".");
      var nums2 = b.Product_version.split(".");
  
      for (var i = 0; i < nums1.length; i++) {
          if (nums2[i]) { // assuming 5..2 is invalid
              if (nums1[i] !== nums2[i]) {
                 return nums2[i] -nums1[i];   
              } // else continue
          } else {
              return 1; // no second number in b
          }
      }
      return -1; // was missing case b.len > a.len
  });
   
    var nextValue2 = nextValue 
    // !== null ? nextValue.replaceAll('"', "") : null;
    if (nextValue2 === null) {
      nextValue2 = result2[0].Id;
    }

    // setSelectedOption(nextValue2);
    setSelectedOption(nextValue2);
    ProductVersions[previousValue2].map((result) => {
      if (result.Id == nextValue2) {
        setSelectedOptionName(result.Product_version);
        localStorage.setItem(
          "CPS_ProductVersionName",
          JSON.stringify(result.Product_version)
        );
      }
    });

    setResult(result2);
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue2));
    setClassNameButton(
      "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-defaul+t ui-state-active"
    );
  };

  useEffect(() => {
    function getDatas() {
      if (Cookies.get("csrf_access_token") === undefined) {
        localStorage.clear();
        history("/");
      } else {
        if(state!==undefined && state!==null && state.productData!==undefined && state.productData!==null)
        {
          productData = state.productData
        }
        if (
          productData != null &&
          productData != undefined &&
          productData != "" 
          
        ) {
          common(productData);
        } else {
          axios
            .get(process.env.REACT_APP_END_POINT + "/ProductVersions", {
              headers: {
                "xsrf-token": Cookies.get("csrf_access_token"),
              },
              mode: "cors",
            })
            .then((response) => {
              productData = response.data;
              common(response.data);
            })
            .catch(function (error) {
              if (error.response != null && error.response.status === 401) {
                localStorage.clear();
                history("/");
              } else {
                setProductStatus("Failed to fetch product versions");
                setDisable(true);
                setClassNameButton(
                  "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
                );
              }
            });
        }
      }
    }
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html>
      <HeaderElements>
        <title>Cloud Provisioning System :: User `{email}`</title>
      </HeaderElements>
      <body>
        <RequestPageImages
          email={email}
          password={password}
          Token={Token}
          productData ={prodData}
        ></RequestPageImages>
        <div id="pageContent">
          <div id="header"></div>

          <div className="pagecontent">
            <div id="Completed" className="hidden"></div>
            <div id="wizardWrapper" className="">
              <div className="page-title">New Provisioning Request</div>
              <div className="page-description">
                This provisioning wizard provides you step by step instructions
                on how to provision an AMI.
              </div>
              <h4 id="progress_name">
                <span className="sbttl" id="product_name">
                  { previousValue !== null ? previousValue.replaceAll('"', "") : null}
                </span>
                <span className="sbttl" id="version_name"></span>
                <span className="sbttl" id="release_name"></span>
                <span className="sbttl" id="build_name"></span>
              </h4>
              <form
                id="wizardForm"
                method="post"
                className="bbq ui-formwizard ui-helper-reset ui-widget ui-widget-content ui-helper-reset ui-corner-all"
              >
                <div id="fieldWrapper">
                  <span
                    id="version_step"
                    className="step ui-formwizard-content ui-helper-reset ui-corner-all"
                    styless="display: none;"
                  >
                    <input
                      type="hidden"
                      name="step_number"
                      value="3"
                      disabled=""
                      className="ui-wizard-content ui-helper-reset ui-state-default"
                    />
                    <span className="error-message" style={{ color: "red" }}>
                      {productStatus}
                    </span>
                    <span className="font_normal_07em_black">
                      Select Product Version
                    </span>
                    <div id="values_2" className="hidden"></div>
                    <label htmlFor="version">Version</label>
                    <select
                      name="version"
                      id="version"
                      className="ui-wizard-content ui-helper-reset ui-state-default"
                      value={selectedOption}
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        setSelectedOptionName(
                          e.target.options[e.target.selectedIndex].innerHTML
                        );
                      }}
                    >
                      {result.map((results) => (
                        <option
                          value={results.Id}
                          key={results.Product_version}
                        >
                          {results.Product_version}
                        </option>
                      ))}
                    </select>
                    <div id="demoNavigation">
                      <input
                        className="navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-defaul+t ui-state-active"
                        id="back"
                        value="Back"
                        type="reset"
                        onClick={() => {
                          history("/provisioningrequest", {
                            state: {
                              previousValue: previousValue,
                              nextValue: nextValue,
                              email: email,
                              password: password,
                              Token: Token,
                              productData : prodData
                            },
                          });
                        }}
                      />
                      <input
                        className={classNameButton}
                        id="next"
                        value="Next"
                        type="button"
                        disabled={disable}
                        onClick={() => {
                          history("/provisioningcredentials", {
                            state: {
                              previousValue: previousValue,
                              nextValue:
                                selectedOption === null
                                  ? nextValue
                                  : selectedOption,
                              email: email,
                              password: password,
                              Token: Token,
                              productversionname: selectedOptionName,
                              productData : prodData
                            },
                          });
                        }}
                      />
                    </div>
                  </span>
                  <p id="data" styless="overflow: auto;"></p>
                </div>
              </form>
              <div id="off_steps" className="hidden"></div>
            </div>
          </div>
          <div id="footer">copyrights and stuff</div>
        </div>
      </body>
    </html>
  );
};

export default SelectProductVersion;
