export const Columns = [
  {
    Header: "Request ID",
    accessor: "request_id",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Date Submitted",
    accessor: "request_created",
  },
  {
    Header: "AWS Region",
    accessor: "region",
  },
  {
    Header: "AWS Account",
    accessor: "account",
  },
  {
    Header: "AMI ID",
    accessor: "ami_id",
  },
];

export default Columns;
