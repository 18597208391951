import { useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import Columns from "./columns";
import { GlobalFilter } from "./GlobalFilter";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

export const TableOutline = () => {
  let [MOCK_DATA, setMockData] = useState([]);
  const history = useNavigate();

  const columns = useMemo(() => Columns, []);
  const data = MOCK_DATA;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 25 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    rows,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = tableInstance;


  const [requestStatus, setRequestStatus] = useState("");

  useEffect(() => {
    function getDatas() {
      if (Cookies.get("csrf_access_token") === undefined) {
        localStorage.clear();
        history("/");
      } else {
        axios
          .get(
            process.env.REACT_APP_END_POINT + "/RequestListDatas",
            { email: JSON.parse(localStorage.getItem("CPS_UserEmail")) },
            {
              mode: "cors",
            }
          )
          .then((response) => {
            setRequestStatus("")
            setMockData(response.data);
          })
          .catch(function (error) {
            if (error.response != null && error.response.status === 401) {
              localStorage.clear();
              history("/");
            } else {
              setRequestStatus("Failed to fetch request datas");
            }
          });
      }
    }
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { globalFilter } = state;

  return (
    <>
    { requestStatus==="" ?
    <div 
    id="tablewrapper"
    >
      <div id="dynamic">
        <div className="dataTables_wrapper" id="reqtable_wrapper">
          <div className="topbar">
            <div id="reqtable_length" className="dataTables_length">
              <label>
                Show{" "}
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                {" "}entries
              </label>
            </div>
            <div className="dataTables_filter" id="reqtable_filter">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
          <table
            {...getTableProps()}
            cellPadding="0"
            cellSpacing="0"
            border="0"
            className="display"
            id="reqtable"
            pagesize={25}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="alignRight sorting"
                      id="headerReactTable"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="alignRight">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="bottombar">
            <div className="dataTables_length" id="reqtable_info">
              Showing{" "}
              {(() => {
                if (pageIndex * pageSize + 1 > rows.length) {
                  return rows.length;
                } else {
                  return pageIndex * pageSize + 1;
                }
              })()}{" "}
              to{" "}
              {(() => {
                if (pageSize * (pageIndex + 1) > rows.length) {
                  return rows.length;
                } else {
                  return pageSize * (pageIndex + 1);
                }
              })()}{" "}
              of {rows.length} entries
            </div>
            <div
              className="dataTables_paginate paging_two_button"
              id="reqtable_paginate"
            >
              <button
                type="button"
                className="paginate_enabled_previous"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              ></button>
              <button
                className="paginate_enabled_next"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    : <span className="error-message" style={{ color: "red" }}>
    {requestStatus}
  </span>}
    </>
  );
};
