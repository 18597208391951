import HeaderElements from "../LoginPage/PageHeader";
import RequestPageImages from "./RequestPageImages";
import "../../css/main.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import DOMPurify from "dompurify";
import Cookies from "js-cookie";

import { useEffect } from "react";

const ProvisioningCredentialsPage = () => {
  let history = useNavigate();
  const { state } = useLocation();
  const [regionStatus, setRegionStatus] = useState("");
  const [disable, setDisable] = useState(false);
  const [classNameButton, setClassNameButton] = useState(
    "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
  );
  const IsGovernmentBased = process.env.REACT_APP_IS_GOVERNMANT_BASED;
  const [errorMessage, setErrorMessage] = useState("");

  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = null,
    productversionname = null ,
    productData = [];
  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = JSON.parse(localStorage.getItem("CPS_UserEmail"));
    }
    if (localStorage.getItem("CPS_PreviousValue") !== null) {
      previousValue = JSON.parse(localStorage.getItem("CPS_PreviousValue"));
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = JSON.parse(localStorage.getItem("CPS_NextValue"));
    }
    if (localStorage.getItem("CPS_ProductVersionName") != null) {
      productversionname = JSON.parse(
        localStorage.getItem("CPS_ProductVersionName")
      );
    }
  } else {
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    productversionname = state.productversionname;
    productData = state.productData;
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem(
      "CPS_PreviousValue",
      JSON.stringify(
        previousValue!== null ? previousValue.replaceAll('\"', "") : null
      )
    );
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
    localStorage.setItem(
      "CPS_ProductVersionName",
      JSON.stringify(productversionname)
    );
  }

  const [AwsAccountNumber, setAwsAccountNumber] = useState("");
  const [SalesOrder, setSalesOrder] = useState("");
  const [CustomerEmailAddress, setCustomerEmailAddress] = useState("");
  let [result, setResult] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(
    () => {
      if (Cookies.get("csrf_access_token") === undefined) {
        localStorage.clear();
        history("/");
      }
      axios
        .get(process.env.REACT_APP_END_POINT + "/AwsRegions", {
          params: {
            productversion: nextValue,
          },
          headers: {
            "xsrf-token": Token,
          },
          mode: "cors",
        })
        .then((response) => {
          setRegionStatus("");
          let result2 = [];
          for (var key in response.data) {
            Object.assign(result2, response.data[key]);
          }
          setSelectedOption(result2[0].Id);
          setResult(result2);
          setDisable(false);
          setClassNameButton(
            "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-defaul+t ui-state-active"
          );
        })
        .catch(function (error) {
          if (error.response != null && error.response.status === 401) {
            localStorage.clear();
            history("/");
          } else {
            setRegionStatus("Failed to fetch Aws Regions");
            setDisable(true);
            setClassNameButton(
              "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
            );
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function handleSubmit(event) {
    event.preventDefault();
    let awsregion = selectedOption,
      productname = previousValue,
      productversion = productversionname,
      awsaccountnumber = AwsAccountNumber,
      salesorder = SalesOrder,
      customeremailaddress = CustomerEmailAddress;
    var awsaccountnumberregex = new RegExp("[0-9]+(-[0-9]+)");
    var numericregex = new RegExp("^[0-9]+$");
    var emailidregex = new RegExp("[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]");

    if (
      awsaccountnumber.length === 14 &&
      awsaccountnumberregex.test(awsaccountnumber) === false
    ) {
      setErrorMessage(
        "Account ID have to be a number of 12 digits and can be separated by hyphen in groups of 4"
      );
      return;
    } else if (awsaccountnumber.length !== 14) {
      if (awsaccountnumber.length !== 12) {
        setErrorMessage(
          "Account ID have to be a number of 12 digits and can be separated by hyphen in groups of 4"
        );
        return;
      }
      if (numericregex.test(awsaccountnumber) === false) {
        setErrorMessage(
          "Account ID have to be a number of 12 digits and can be separated by hyphen in groups of 4"
        );
        return;
      }
    }
    setErrorMessage("");

    if (IsGovernmentBased === "1") {
      if (salesorder.length == 0) {
        setErrorMessage("Please enter a valid sales order");
        return;
      }
      if (emailidregex.test(customeremailaddress) === false) {
        setErrorMessage("Please enter a valid email address");
        return;
      }
    }
    setErrorMessage("");
    let item = {
      awsaccountnumber,
      salesorder,
      customeremailaddress,
      awsregion,
      productname,
      productversion,
      email,
      password,
    };
    setClassNameButton(
      "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
    );

    DOMPurify.sanitize(item);
    axios
      .post(process.env.REACT_APP_END_POINT + "/submit", item, {
        headers: {
          "x-csrf-token": Cookies.get("csrf_access_token"),
        },
        mode: "cors",
      })
      .then((response) => {
        localStorage.setItem(
          "CPS_ReferenceID",
          JSON.stringify(response.data.ReferenceID)
        );
        history("/CpsSubmitPage", {
          state: {
            previousValue: previousValue,
            nextValue: nextValue,
            email: email,
            password: password,
            Token: Token,
            ReferenceID: response.data.ReferenceID,
            productData : productData 
          },
        });
      })
      .catch(function (error) {
        history("/CpsSubmitPage", {
          state: {
            previousValue: previousValue,
            nextValue: nextValue,
            email: email,
            password: password,
            Token: Token,
            ReferenceID: null,
            productData  : productData 
          },
        });
        // localStorage.clear();
        // history("/");
      });
  }

  return (
    <html>
      <HeaderElements>
        <title>Cloud Provisioning System :: User `{email}`</title>
      </HeaderElements>
      <body>
        <RequestPageImages
          email={email}
          password={password}
          Token={Token}
          productData ={productData}
        ></RequestPageImages>
        <div id="pageContent">
          <div id="header"></div>

          <div className="pagecontent">
            <div id="Completed" className="hidden"></div>
            <div id="wizardWrapper" className="">
              <div className="page-title">New Provisioning Request</div>
              <div className="page-description">
                This provisioning wizard provides you step by step instructions
                on how to provision an AMI.
              </div>
              <h4 id="progress_name">
                <span className="sbttl" id="product_name">
                  {previousValue !== null ? previousValue.replaceAll('"', "") : null} {productversionname}
                </span>
              </h4>
              <h5 className="" id="status">
                {errorMessage}
              </h5>
              <form
                id="wizardForm"
                method="post"
                className="bbq ui-formwizard ui-helper-reset ui-widget ui-widget-content ui-helper-reset ui-corner-all"
                autocomplete="off"
              >
                <div id="fieldWrapper">
                  <span
                    id="env_step"
                    className="step ui-formwizard-content ui-helper-reset ui-corner-all"
                    styless="display: none;"
                  >
                    <input
                      type="hidden"
                      name="step_number"
                      value="6"
                      disabled=""
                      className="ui-wizard-content ui-helper-reset ui-state-default"
                    />
                    <span className="error-message" style={{ color: "red" }}>
                      {regionStatus}
                    </span>
                    <span className="font_normal_07em_black">
                      Provisioning Credentials
                    </span>
                    <div className="step-description">
                      This step will start provisioning of an AMI in the region
                      specified below and access will be available to the AWS
                      Account specified below.
                    </div>
                    <div id="values_5" className="hidden"></div>
                    <table width="" cellSpacing="" cellPadding="0" border="0">
                      <tbody>
                        {IsGovernmentBased === "1" ? (
                          <>
                            <tr>
                              <td>
                                <label htmlFor="gov_sales_order">
                                  Sales Order#
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="gov_sales_order"
                                  id="gov_sales_order"
                                  className="ui-wizard-content ui-helper-reset ui-state-default"
                                  value={SalesOrder}
                                  onChange={(e) =>
                                    setSalesOrder(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="gov_cust_email">
                                  Customer E-Mail Address
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="gov_cust_email"
                                  id="gov_cust_email"
                                  className="ui-wizard-content ui-helper-reset ui-state-default"
                                  value={CustomerEmailAddress}
                                  onChange={(e) =>
                                    setCustomerEmailAddress(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            <label htmlFor="reg">AWS Region</label>
                          </td>
                          <td>
                            <select
                              name="reg"
                              id="reg"
                              disabled=""
                              className="ui-wizard-content ui-helper-reset ui-state-default"
                              value={selectedOption}
                              onChange={(e) =>
                                setSelectedOption(e.target.value)
                              }
                            >
                              {result.map((result) => (
                                <option
                                  key={result.Id}
                                  value={result.Id}
                                >
                                  {result.Aws_region}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="accid">AWS Account Number</label>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="accid"
                              id="accid"
                              value={AwsAccountNumber}
                              onChange={(e) =>
                                setAwsAccountNumber(e.target.value)
                              }
                              disabled=""
                              className="ui-wizard-content ui-helper-reset ui-state-default"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="demoNavigation">
                      <input
                        className="navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-defaul+t ui-state-active"
                        id="back"
                        value="Back"
                        type="reset"
                        onClick={() => {
                          history("/selectproductversion", {
                            state: {
                              previousValue: previousValue,
                              nextValue: nextValue,
                              email: email,
                              password: password,
                              Token: Token,
                              productData : productData
                            },
                          });
                        }}
                      />
                      <input
                        className={classNameButton}
                        id="next"
                        value="Submit"
                        type="submit"
                        disabled={disable}
                        onClick={handleSubmit}
                      />
                    </div>
                  </span>
                  <p id="data" styless="overflow: auto;"></p>
                </div>
              </form>
              <div id="off_steps" className="hidden"></div>
            </div>
          </div>
          <div id="footer">copyrights and stuff</div>
        </div>
      </body>
    </html>
  );
};

export default ProvisioningCredentialsPage;
