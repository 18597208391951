import "./App.css";
import LoginPageCloudProvisioningSystem from "./components/LoginPage/LoginPageCloudProvisioningSystem";
import NewProvisioningRequestSystem from "./components/ProvisioningRequestPage/NewProvisioningRequestPage";
import { Route, Routes } from "react-router-dom";
import RequestListsPage from "./components/ProvisioningRequestPage/RequestListsPage";
import SelectProductVersion from "./components/ProvisioningRequestPage/SelectProductVersion";
import ProvisioningCredentialsPage from "./components/ProvisioningRequestPage/ProvisioningCredentialsPage";
import CpsSubmitPage from "./components/ProvisioningRequestPage/CpsSubmitPage";

function App() {

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<LoginPageCloudProvisioningSystem />}
      ></Route>
      <Route
        path="/provisioningrequest"
        element={<NewProvisioningRequestSystem />}
      ></Route>
      <Route path="/requestlist" element={<RequestListsPage />}></Route>
      <Route
        path="/selectproductversion"
        element={<SelectProductVersion />}
      ></Route>
      <Route
        path="/provisioningcredentials"
        element={<ProvisioningCredentialsPage />}
      ></Route>
      <Route
      path="/CpsSubmitPage"
      element={<CpsSubmitPage />}
      ></Route>
    </Routes>
  );
}

export default App;
