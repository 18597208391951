class AppConfig {
    config = undefined
    get(){
        if(this.config == undefined){
            throw new Error(
                "Application config has not been set"
            )
        }
    }
    set(value){
        if(this.config == undefined){
            this.config = value
        } else {
            throw new Error(
                "Application config has not been set"
            );
        }
    }
}

export const appConfig = new AppConfig()
export const appConfigUrl = "config.json"