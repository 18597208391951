import "../../css/main.css";
import HeaderElements from "./PageHeader";
import HeaderImages from "./HeaderImages";
import LoginPageDescription from "./LoginPageDescription";
import LoginPageUsernameAndPassword from "./LoginPageUsernameAndPassword";
/*eslint-disable jsx-a11y/anchor-is-valid */
const LoginPageCloudProvisioningSystem = () => {
  return (
    <html>
      <HeaderElements></HeaderElements>
      <body>
        <HeaderImages></HeaderImages>
        <div id="pageContent">
          <div id="header"></div>
          <div className="pagecontent">
            <LoginPageDescription></LoginPageDescription>
            <LoginPageUsernameAndPassword></LoginPageUsernameAndPassword>
          </div>
        </div>
        <div id="footer">copyrights and stuff</div>
      </body>
    </html>
  );
};

export default LoginPageCloudProvisioningSystem;
