import "../../css/main.css";
import { appConfig } from "../Store/AppConfig";
export const LoginPageDescription = () => {
  return (
    <div>
      <div className="page-title">Cloud Provisioning System</div>
      <div className="page-description" style={{fontSize: '1.8em' , color:'red'}}>
        <br></br>
      <b style={{ fontWeight: 2000, textDecoration: 'underline' }}>UPDATE:</b> This website can no longer be used for provisioning. Please submit your request here on our <a href={appConfig.config.Ui_description_url} target="_blank" style={{fontSize: 'inherit' , color:'red'}}> Provisioning form.</a></div>
      <br></br>
    </div>
  );
};

export default LoginPageDescription;
