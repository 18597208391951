import HeaderElements from "../LoginPage/PageHeader";
import RequestPageImages from "./RequestPageImages";
import ProductsListsPage from "./SelectProductList";
import "../../css/main.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Cookies from "js-cookie";

const NewProvisioningRequestSystem = () => {
  let history = useNavigate();

  const { state } = useLocation();
  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = null , 
    productData = [] ;

  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = JSON.parse(localStorage.getItem("CPS_UserEmail"));
    }
    if (
      localStorage.getItem("CPS_PreviousValue") !== null ||
      localStorage.getItem("CPS_PreviousValue") !== undefined
    ) {
      previousValue = JSON.parse(localStorage.getItem("CPS_PreviousValue"));
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = JSON.parse(localStorage.getItem("CPS_NextValue"));
    }
  } else {
    
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    productData = state.productData ;
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem("CPS_PreviousValue", JSON.stringify( previousValue!== null ? previousValue.replaceAll('"', "") : null));
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
  }

  useEffect(() => {
    if (Cookies.get("csrf_access_token") === undefined) {
      localStorage.clear();
      history("/");
    }
  });

  return (
    <html>
      <HeaderElements>
        <title>Cloud Provisioning System :: User `{email}`</title>
      </HeaderElements>
      <body>
        <RequestPageImages
          Token={Token}
          email={email}
          password={password}
          productData ={productData}
        ></RequestPageImages>
        <div id="pageContent">
          <div id="header"></div>

          <div className="pagecontent">
            <div id="Completed" className="hidden"></div>
            <div id="wizardWrapper" className="">
              <div className="page-title">New Provisioning Request</div>
              <div className="page-description">
                This provisioning wizard provides you step by step instructions
                on how to provision an AMI.
              </div>
              <h4 id="progress_name">
                <span className="sbttl" id="product_name"></span>
                <span className="sbttl" id="version_name"></span>
                <span className="sbttl" id="release_name"></span>
                <span className="sbttl" id="build_name"></span>
              </h4>
              <form
                id="wizardForm"
                method="post"
                className="bbq ui-formwizard ui-helper-reset ui-widget ui-widget-content ui-helper-reset ui-corner-all"
              >
                <div id="fieldWrapper">
                  <ProductsListsPage
                    Token={Token}
                    email={email}
                    password={password}
                    previousValue={previousValue}
                    nextValue={nextValue}
                    productData ={productData }
                  ></ProductsListsPage>
                  <p id="data" styless="overflow: auto;"></p>
                </div>
              </form>
              <div id="off_steps" className="hidden"></div>
            </div>
          </div>
          <div id="footer">copyrights and stuff</div>
        </div>
      </body>
    </html>
  );
};

export default NewProvisioningRequestSystem;
