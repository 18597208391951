const HeaderElements = () => {
  return (
    <head>
      <title>Cloud Provisioning System :: Page title</title>
      <link
        type="/text/css"
        rel="stylesheet"
        href="/css/ui-lightness/jquery-ui-1.8.2.custom.css"
      ></link>
      <link rel="stylesheet" href="/css/main.css" 
      type="/text/css"
      ></link>
    </head>
  );
};

export default HeaderElements;
