import "../../css/main.css";
import React, { useEffect, useState } from "react";
import { useNavigate ,useHref} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export const LoginPageUsernameAndPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const DOMPurify = require("dompurify")(window);
  const [classNameButton, setClassNameButton] = useState(
    "button ui-state-active"
  );

  let productData =[]

  axios.defaults.withCredentials = true;

  let history = useNavigate();
  useEffect(() => {
    if (Cookies.get("csrf_access_token") !== undefined) {
      axios
        .post(
          process.env.REACT_APP_END_POINT + "/LogoutPage",
          // {
          //   email: JSON.parse(localStorage.getItem("CPS_UserEmail")),
          // },
          {
            headers: {
              "x-csrf-token": Cookies.get("csrf_access_token"),
            },
            mode: "cors",
          }
        )
        .then((response) => {
          Cookies.remove("csrf_access_token");
          localStorage.clear();
          history("/");
        })
        .catch(function (error) {
          localStorage.clear();
          Cookies.remove("csrf_refresh_token");
          Cookies.remove("csrf_access_token");
          history("/");
        });
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(event) {
    setClassNameButton( "button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled");
    event.preventDefault();
    let item = { email, password };
    DOMPurify.sanitize(item);
    axios
      .post(process.env.REACT_APP_END_POINT + "/LoginPage", item, {
        mode: "cors",
        credentials: "include",
      })
      .then((response) => {
        if (response.status === 200) {
          axios
          .get(
            process.env.REACT_APP_END_POINT + "/ProductVersions",
            {
              headers: {
                "x-csrf-token": Cookies.get("csrf_access_token"),
              },
              mode: "cors",
            }
          )
          .then((response) => {
            productData = response.data
            history("/provisioningrequest", {
              state: {
                email: email,
                password: password,
                previousValue: null,
                nextValue: null,
                productData : productData
              },
            });
          })
          localStorage.setItem(
            "CPS_UserName",
            JSON.stringify(response.data.username)
          );
        } else {
          setLoginStatus("wrong username or password");
        }
      })
      .catch(function (error) {
        setClassNameButton("button ui-state-active")
        if (error.response === undefined) {
          setLoginStatus("Server unavailable");
        } else {
          setLoginStatus("wrong username or password");
        }
      });
  }

  return (
    <div>
      <div className="popupholder">
        <div className="c-box popup">
          <div className="error-message">{loginStatus}</div>
          <form autoComplete="off">
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="username">Username</label>
                  </td>
                  <td>
                    <input
                      className="text"
                      name="username"
                      id="username"
                      value={email}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="password">Password</label>
                  </td>
                  <td>
                    <input
                      className="text"
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="spacer"></td>
                </tr>
                <tr>
                  <td colSpan="2" align="right" className="nav">
                    <input
                      className={classNameButton}
                      type="submit"
                      value="Login"
                      onClick={handleSubmit}
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPageUsernameAndPassword;
