import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router";
import "../../css/ui-lightness/jquery-ui-1.8.2.custom.css";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const ProductsListsPage = (props) => {
  const { state } = useLocation();
  let history = useNavigate();
  const [productStatus, setProductStatus] = useState("");
  const [disable, setDisable] = useState(false);
  const [classNameButton, setClassNameButton] = useState(
    "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
  );
  const [productData , setProductData] = useState([]);
  
  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = null,
    prodData = []
    if(props!=null)
    {
      prodData = props.productData
    }
  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = localStorage.getItem("CPS_UserEmail");
    }
    if (localStorage.getItem("CPS_PreviousValue") !== null) {
      previousValue = localStorage.getItem("CPS_PreviousValue");
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = localStorage.getItem("CPS_NextValue");
    }
  } else {
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    if(state.productData!=null && state.productData!=undefined && state.productData.length > 0)
    {
       prodData = state.productData;
    }
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem("CPS_PreviousValue", JSON.stringify( previousValue!== null ? previousValue.replaceAll('"', "") : null));
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
  }

  let [Product, setProduct] = useState([]);

  const [selectedOption, setSelectedOption] = useState();

  const common=(productData)=>{
    setProductData(productData)
    setProductStatus("");
  //  setProduct(response.data);
    let firstresult = [] 
    for(var value in productData)
    {
      firstresult.push(value)
    }
    firstresult = firstresult.sort();
    setProduct(firstresult);
    //response.data.map((result) => result.Product_name);

    var previousValue2 = firstresult[0];

    var previousState = previousValue;

    // setSelectedOption(response.data[0].Product_name);
    setSelectedOption(previousValue2);
    if (previousState !==null && previousState!==undefined && previousState!=="null") {
      previousValue2 = previousState;
      setSelectedOption(previousValue2);
    }
    previousValue2 =
      previousValue2 !== null ? previousValue2.replaceAll('"', "") : " ";
    localStorage.setItem(
      "CPS_PreviousValue",
      JSON.stringify(previousValue2)
    );
    setDisable(false);
    setClassNameButton(
      "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-defaul+t ui-state-active"
    );
  }

  useEffect(() => {
    if (Cookies.get("csrf_access_token") === undefined) {
      localStorage.clear();
      history("/");
    }
    function getDatas() {
      if(prodData !=null &&  prodData !="" && prodData !=undefined)
      {
        common(prodData);
      }
      else
      {
      axios
        .get(
          process.env.REACT_APP_END_POINT + "/ProductVersions",
          {
            headers: {
              "xsrf-token": Token,
            },
            mode: "cors",
          }
        )
        .then((response) => {
          common(response.data);
        })
        .catch(function (error) {
          if (error.response != null && error.response.status === 401) {
            localStorage.clear();
            history("/");
          } else {
            setProductStatus("Failed to fetch product lists");
            setDisable(true);
            setClassNameButton(
              "navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
            );
          }
        });
    }
  }
   getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    
      <span
        className="step ui-formwizard-content ui-helper-reset ui-corner-all"
        id="product_step"
        styless="display: inline;"
      >
        <input
          type="hidden"
          name="step_number"
          value="2"
          className="ui-wizard-content ui-helper-reset ui-state-default"
        />
        <span className="error-message" style={{ color: "red" }}>
          {productStatus}
        </span>
        <span className="font_normal_07em_black">Select Product</span>
        <label htmlFor="product">Product</label>
        <select
          name="product"
          id="product"
          className="ui-wizard-content ui-helper-reset ui-state-default"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          {Product.map((result) => (
            <option value={result} key={result}>
              {result}
            </option>
          ))}
        </select>
        <div id="demoNavigation">
          <input
            className="navigation_button button ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-disabled"
            id="back"
            value="Back"
            type="reset"
            disabled
          />
          <input
            className={classNameButton}
            id="next"
            value="Next"
            type="button"
            disabled={disable}
            onClick={() => {
              history("/selectproductversion", {
                state: {
                  previousValue: selectedOption,
                  nextValue: null,
                  email: props.email,
                  password: props.password,
                  Token: props.Token,
                  productData : productData 
                },
              });
            }}
          />
        </div>
      </span>
    </>
  );
};

export default ProductsListsPage;
