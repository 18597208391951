/*eslint-disable jsx-a11y/anchor-is-valid */
import cloud_security_solutions_logo from "../../images/cloud_security_solutions_logo.png";
import Thales_logo_white from "../../images/Thales_logo_white.png";
import { useNavigate } from "react-router-dom";
import "../../css/main.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router";
import { useEffect ,useState } from "react";

axios.defaults.withCredentials = true;

export const RequestPageImages = (props) => {

let productData =[]

  const { state } = useLocation();
  let history = useNavigate();
  
  function handleSubmit(event) {
    axios
      .post(
        process.env.REACT_APP_END_POINT + "/LogoutPage",
        { email: props.email, password: props.password },
        {
          headers: {
            "x-csrf-token": Cookies.get("csrf_access_token"),
          },
          mode: "cors",
          credentials: "include",
        }
      )
      .then((response) => {
        Cookies.remove("csrf_access_token");
        localStorage.clear();
        history("/");
      })
      .catch(function (error) {
        Cookies.remove("csrf_access_token");
        localStorage.clear();
        history("/");
      });
  }

  return (
    <div className="header_title">
      <img
        align="left"
        style={{position:"relative",left:"15px"}}
        src={cloud_security_solutions_logo}
        id="logo"
        width="300"
        height="45"
        alt="Cloud Security Solutions Logo"
      />
      <div id="signininfo">
        Welcome,{" "}
        {localStorage.getItem("CPS_UserName") !== null
          ? localStorage.getItem("CPS_UserName").replaceAll('"', "")
          : " "}
        &nbsp;|&nbsp;
        <a onClick={handleSubmit}>
          <u>Log out</u>
        </a>
      </div>
      <div id="topmenu" className="pointedcursor">
        <ul>
          <li>
            <div
              className="selected"
              title="New request wizard"
              onClick={() => {
                history("/provisioningrequest", {
                  state: {
                    Token: props.Token,
                    email: props.email,
                    password: props.password,
                    previousValue: null,
                    nextValue: null,
                    productData : props!= null && props.productData!=null && props.productData!=undefined?props.productData : productData

                  },
                });
              }}
            >
              New Provisioning Request
            </div>
          </li>
          <li>
            <a
              title="Requests list"
              onClick={() => {
                history("/requestlist", {
                  state: {
                    Token: props.Token,
                    email: props.email,
                    password: props.password,
                    previousValue: null,
                    nextValue: null,
                    productData : props!= null && props.productData!=null && props.productData!=undefined?props.productData : productData
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >
              Requests List
            </a>
          </li>
        </ul>
      </div>
      <img
        src={Thales_logo_white}
        position="relative"
        id="logo_safenet"
        width="180"
        height="44"
        alt="Gemalto Logo White"
      />
    </div>
  );
};

export default RequestPageImages;
