import HeaderElements from "../LoginPage/PageHeader";
import RequestPageImages from "./RequestPageImages";
import "../../css/main.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useEffect } from "react";
import Cookies from "js-cookie";

const FinalSubmissionPage = () => {
  const { state } = useLocation();

  var Token = null,
    email = null,
    password = null,
    previousValue = null,
    nextValue = null,
    ReferenceID = null,
    productData  = [];
  if (state === undefined || state === null) {
    if (localStorage.getItem("CPS_UserEmail") !== null) {
      email = JSON.parse(localStorage.getItem("CPS_UserEmail"));
    }
    if (localStorage.getItem("CPS_PreviousValue") !== null) {
      previousValue = JSON.parse(localStorage.getItem("CPS_PreviousValue"));
    }
    if (localStorage.getItem("CPS_NextValue") !== null) {
      nextValue = JSON.parse(localStorage.getItem("CPS_NextValue"));
    }
    if (localStorage.getItem("CPS_ReferenceID") !== null) {
      ReferenceID = JSON.parse(localStorage.getItem("CPS_ReferenceID"));
    }
  } else {
    Token = state.Token;
    email = state.email;
    password = state.password;
    previousValue = state.previousValue;
    nextValue = state.nextValue;
    ReferenceID = state.ReferenceID;
    productData  = state.productData ;
    localStorage.setItem("CPS_UserEmail", JSON.stringify(email));
    localStorage.setItem("CPS_PreviousValue", JSON.stringify(previousValue!== null ? previousValue.replaceAll('"', "") : null));
    localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
    localStorage.setItem("CPS_ReferenceID", JSON.stringify(ReferenceID));
  }

  const history = useNavigate();

  localStorage.setItem("CPS_NextValue", JSON.stringify(nextValue));
  useEffect(() => {
    if (Cookies.get("csrf_access_token") === undefined) {
      localStorage.clear();
      history("/");
    }
  });

  return (
    <html>
      <HeaderElements>
        <title>Cloud Provisioning System :: User `{email}`</title>
      </HeaderElements>
      <body>
        <RequestPageImages
          email={email}
          password={password}
          Token={Token}
          productData ={productData}
        ></RequestPageImages>
        <div id="pageContent">
          <div id="header"></div>

          <div className="pagecontent">
            <div id="Completed" className="">
              <div className="hspan_small"></div>
              <div className="popupholder">
                {ReferenceID === null ||
                ReferenceID === "" ||
                ReferenceID === undefined ? (
                  <span className="error-message" style={{ color: "red" }}>
                    Failed!Try after sometimes
                  </span>
                ) : (
                  <div className="popup">
                    <h3>Image request created</h3>
                    <p>
                      Request reference ID:{" "}
                      <input
                        type="text"
                        readOnly={true}
                        value={ReferenceID}
                      ></input>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default FinalSubmissionPage;
